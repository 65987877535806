/**
 * Fonts
**/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Noto+Sans&display=swap');

$primary-font: 'Inter', sans-serif;

/**
 * Global variables
 **/

$image_path: 'images/';

/**
 * Colors
 **/

$primary-color: #FFFFFF;
$secondary-color: #FFFFFF;

$blue: #ACD9F5;
$blue-secondary: #0BC3EE;
$mint: #9DE9D3;
$pink: #FAC1C2;
$yellow: #FBEB7C;

$white: #FFFFFF;
$black: #000000;
/**
 * Template - Professionele schoonmaak
**/

.usp{
    ul{
        border: 1px solid $black;
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: center;
    }

    li{
        display: inline-block;
        margin-right: 60px;
        padding-left: 10px;
        position: relative;

        &:before{
            content:'';
            position: absolute;
            background-image: url('images/checkmark.svg');
            width: 17px;
            height: 13px;
            left: -15px;
            top: 4px;
        }
    }

    @include media-breakpoint-down(md){
        ul{
            padding-left: 0;
        
            li{
                margin-right: 5px;

                &:before{
                    display: none;
                }
            }
        }
    }    
}

.onze-diensten{
    h2{
        margin-top: 25px;        
    }

    ul{
        padding-left: 0;
        list-style-type: none;
        margin-top: 50px;

        li{
            border-top: 1px solid $black;
            font-size: 18px;
            line-height: 32px;
            padding-top: 10px;
            padding-bottom: 10px;
            position: relative;

            transition: all .3s ease;

            &:last-of-type{
                border-bottom: 1px solid $black;
            }

            &:before{
                content:'';
                position: absolute;
                height: 15px;
                width: 15px;
                border: 1.5px solid $black;
                left: - 30px;
                top: 22px;
                opacity: 0;

                transition: all .3s ease;
            }

            &:hover{
                padding-left: 30px;

                &:before{
                    opacity: 1;
                    left: 0;
                }
            }

            @include media-breakpoint-up(md){
                font-size: 24px;
                line-height: 38px;
            }
        }
    }   
}

.onze-werkwijze{
    .align-center{
        margin-left: 25px;
    }

    h2{
        @include media-breakpoint-down(md){
            margin-top: 30px;
            margin-bottom: 25px;
        }
    }    
}

.sectoren{
    .sectoren-list{
        border-top: 1.5px solid $black;
        border-bottom: 1.5px solid $black;
        list-style-type: none;
        padding-top: 35px;
        padding-bottom: 40px;        

        -moz-column-count: 1;
        -moz-column-gap: 20px;
        -webkit-column-count: 1;
        -webkit-column-gap: 20px;
        column-count: 1;
        column-gap: 20px;        

        li{
            font-size: 18px;
            line-height: 38px;    
            
            @include media-breakpoint-up(md){
                font-size: 24px;
                line-height: 48px;
            }
        }

        @include media-breakpoint-up(md){
            -moz-column-count: 2;
            -moz-column-gap: 20px;
            -webkit-column-count: 2;
            -webkit-column-gap: 20px;
            column-count: 2;
            column-gap: 20px;                
        }

        @include media-breakpoint-up(lg){
            -moz-column-count: 3;
            -moz-column-gap: 20px;
            -webkit-column-count: 3;
            -webkit-column-gap: 20px;
            column-count: 3;
        }
    }
}
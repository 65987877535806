/**
 * Testimonials
**/

.testimonial{
    text-align: center;
    padding: 35px;

    @include media-breakpoint-up(md){
        padding: 50px 100px;
    }

    &__quote{
        font-size: 18px;
        font-weight: 300;
        line-height: 28px;

        border-bottom: 1.5px solid $black;
        padding-bottom: 25px;
        margin-bottom: 25px;

        @include media-breakpoint-up(md){
            font-size: 24px;
            line-height: 36px;        
        }        
    }

    &__author{
        font-size: 20px;
        line-height: 36px;
        text-transform: uppercase;
        margin-bottom: 25px;
    }
}

.slick-dots{
    display: inline-block;
    position: absolute;
    bottom: 25px;
    width: calc(100% - 24px);
    text-align: center;
    padding-left: 0; 

    li{
        display: inline;

        button{
            font-size: 0;
            height: 15px;
            width: 15px;
            border: 1.5px solid #000;
            background: transparent;
            margin-right: 10px;
            margin-left: 10px;  
            transition: background .3s ease-in;          
        }

        &.slick-active{
            button{
                background: $black;
            }
        }
    }
}
/**
 * Forms
 **/

/**
 * Contact 7 Forms
**/

form.wpcf7-form{
    padding: 25px;
    background-color: $blue;
    border-radius: 5px;
    border-bottom: 15px solid $blue-secondary;    

    @include media-breakpoint-up(lg){
        padding: 65px;        
    }

    label{
        display: block;
        width: 100%;        
    }

    input,
    textarea{
        width: 100%;
        background-color: $white;
        border: 0;
        height: 45px;
        border-radius: 5px;        
    }

    input[type="file"]{
        background-color: transparent;        
    }

    input[type="submit"]{
        width: 250px;
        background-color: transparent;
        border: 1px solid $black;
        margin-top: 25px; 
    }

    textarea{
        height: 200px;        
    }
}
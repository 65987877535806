/**
 * Mobile only
 **/

@include media-breakpoint-down(lg) {
	#menu-entries{
		display: none !important;
	
		&.show{
			display: block !important;
			width: 100% !important;
		}

		ul{
			width: 100%;
			border-top: 1px solid #F2F2F2;
			padding-bottom: 15px;
			margin-top: 15px;			
		}
	}

	.navbar{
		display: block !important;
	}

	.navbar-toggler{
		border: 0;
		float: right;
	}
}

.navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8, %3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(172, 217, 245, 1.0)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-brand{
	margin-top: 15px;
	margin-bottom: 15px;

	@include media-breakpoint-down(lg){
		img{
			max-width: 75px;
		}
	}
}

#menu-entries{
	flex-direction: row-reverse;

	.dropdown.child{
		position: relative !important;
		.dropdown-sub{
			position: absolute !important;
			right: 0;
		}
	}

	.nav-link{
		color: $black;
		font-size: 15px;
		margin-right: 15px;
		font-weight: 400;
		line-height: 42px;

		@include media-breakpoint-up(xl){
			font-size: 16px;
			margin-right: 35px;
		}
	}

	.navbar-toggler{
		float: right;
		position: relative;
		z-index: 10000;
	}	
}

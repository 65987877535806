/**
 * Blog
 **/
 
.page-template-template-blog{
    h1{
        margin-top: 100px;
    }
}

.blogs{
    a{
        text-decoration: none;
    }

    .blog{
        h2{
            font-size: 20px;
            text-transform: inherit;
            margin-top: 15px;
            margin-bottom: 75px;
        }
    }
}
// General

.cb-module{

	&__mbv{
		margin-top: 2rem;
	}

	&__mon{
		margin-bottom: 2rem;
	}

	img{
		max-width: 100%;
	}

	.img-wrap{
		display: block;
		width: 100%;
		position: relative;
		overflow: hidden;

		@include media-breakpoint-down(xxl){
			height: 400px;		
		}

		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: 50% 50%;
		}
	}
}
			

// Image Left

.img-left{
	.img-col{
		img{
			@include media-breakpoint-down(md){
				margin-bottom: 25px;
			}
		}
	}
}

// Image Right

.img-right{
	.img-col{
		img{
			@include media-breakpoint-down(md){
				margin-top: 25px;
			}
		}
	}
}

// Full Width Images

.full-width-img{
	.img-wrap{
		height: 500px;

		@include media-breakpoint-down(xxl){
			height: 400px;		
		}

		@include media-breakpoint-down(lg){
			height: 350px;		
		}

		@include media-breakpoint-down(md){
			height: 275px;		
		}

		@include media-breakpoint-down(sm){
			height: 250px;		
		}

	}
}

// Two Images

.two-images{
	.img-col{
		.img-wrap{
			height: 400px;

			@include media-breakpoint-down(xxl){
				height: 325px;		
			}

			@include media-breakpoint-down(lg){
				height: 225px;		
			}

			@include media-breakpoint-down(md){
				height: 275px;
				margin-bottom: 25px;
			}

			@include media-breakpoint-down(sm){
				height: 250px;		
			}
		}

		&:last-of-type{
			.img-wrap{
				margin-bottom: 0 !important;
			}
		}
	}
}

// Three Images

.three-images{
	.img-col{
		.img-wrap{
			height: 325px;

			@include media-breakpoint-down(xxl){
				height: 275px;		
			}

			@include media-breakpoint-down(lg){
				height: 175px;		
			}

			@include media-breakpoint-down(md){
				height: 275px;
				margin-bottom: 25px;
			}

			@include media-breakpoint-down(sm){
				height: 250px;		
			}
		}

		&:last-of-type{
			.img-wrap{
				margin-bottom: 0 !important;
			}
		}
	}
}
/**
 * Standaard template
**/

.page-template-default{
    .content-wrapper{
        margin-top: 100px;
    }
}

.article-body{
    h2{
        font-size: 22px;
        text-transform: inherit;
        margin-top: 15px;        
    }

    h3{
        font-size: 20px;
        text-transform: inherit;
        margin-top: 15px;            
    }
}
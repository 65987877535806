.row:before, .row:after {
	display: none !important;
}

.desktop-only{
	display: block;

	@include media-breakpoint-down(lg){
		display: none;
	}
}

.mobile-only{
	display: none;

	@include media-breakpoint-down(lg){
		display: block;
	}
}

.wrapper img{
	width: 100%;
}

.flex{
    display: flex;
	align-items: center;
}

.align-center{
	align-content: center;
}

.content-block{
	.btn{
		margin-top: 25px;
	}
}

.block-lg{
	@include media-breakpoint-down(xl){
		display: block !important;
	}
}
/**
 * Utils
**/

/**
 * Backgrounds
**/
@each $name, $color in (
    'blue': $blue,
    'yellow': $yellow,
    'mint': $mint,
    'pink': $pink,
    'white': $white,
    'black': $black,
    ) {
	.bg--#{$name} {
		background-color: $color;
  	}
}

/**
 * Blocks
**/
@each $name, $size in ('10': 10, '20': 20, '25': 25, '35': 35, '50': 50, '75': 75, '95': 95,'100': 100, '120': 120, '150': 150) {
	$mobile-size: calc($size/2);
    
    .block-#{$name} {
		padding-top: #{$size}px;
		padding-bottom: #{$size}px;
  	}

    @include media-breakpoint-down(md){
        .block-#{$name} {
            padding-top: #{$mobile-size}px;
            padding-bottom: #{$mobile-size}px;
          }        
    }
}

.block-120{
    @include media-breakpoint-down(xl){
        padding-bottom: 60px;
        padding-top: 60px;
    }
}

/**
 * Helpers
**/
.border-5{
    border-radius: 5px;
}
/**
 * Typography
**/

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body{
    font-family: $primary-font;
}

h1,
h2,
h3{
    font-weight: 400;
    margin-bottom: 25px;
    text-transform: uppercase;
}

h1{
    @include media-breakpoint-up(lg){
        font-size: 40px;
        line-height: 48px;
    }
}

h2{
    @include media-breakpoint-up(lg){
        font-size: 44px;
    }
}

p{
    font-size: 16px;
    line-height: 30px;
}

a{
    color: $black;
    text-underline-offset: 4px;
    transition: color .2s ease-in;

    &:hover{
        color: $blue-secondary;
    }
}

/**
 * Buttons
**/

.btn{
    border-radius: 5px;
    display: inline-block;
    font-size: 16px;
    text-decoration: none;
    padding: 10px 20px;
    margin-right: 15px;
    transition: all .3s ease;

    @include media-breakpoint-up(md){
        padding: 10px 40px;
        margin-right: 30px;
    }

    &:hover{
        color: $black;
    }

    &--primary{
        border: 1px solid $black;

        &:hover{
            background-color: $black;
            color: $white;
        }
    }

    &--blue{
        background-color: $blue;
        border: 1px solid $blue;

        &:hover{
            border: 1px solid $black;
            background-color: transparent;
            color: $black;
        }
    }

    &--header{
        padding: 16px 20px;
        margin-right: 0;

        @include media-breakpoint-up(xl){
            padding: 16px 30px;
        }
    }
}

.content-block{
    li.btn-wrap{
        line-height: 40px;
    }
}
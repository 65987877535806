/**
 * Marquee
 **/

 .marquee {
    --gap: 1rem;
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);

    li{
        font-size: 44px;
        line-height: 54px;
        text-transform: uppercase;
        list-style-type: square;
        margin-left: 35px;        
    }
  }
  
  .marquee__content {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    min-width: 100%;
    gap: var(--gap);
  }
  
  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - var(--gap)));
    }
  }

  .enable-animation .marquee__content {
    animation: scroll 120s linear infinite;
  }

  /* Reverse animation */
 .marquee--reverse .marquee__content {
    animation-direction: reverse;
  }
/**
 * Contact
**/

@include media-breakpoint-up(md){
    .contact-info{
        margin-top: 100px;     
    }
}

@include media-breakpoint-down(md){
    .contact-info{
        .btn{
            width: 100%;
            text-align: center;
            margin-bottom: 20px;
        }   
    }

    .contact-content-block{
        margin-bottom: 35px;
    }
}

.adress-info,
.contact-info-block{
    padding: 30px 45px;
    margin-bottom: 30px;

    ul{
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;
    }
}

.adress-info{
    ul{
        font-size: 16px;
    }
}

.contact-info-block{
    border: 1px solid $black;

    a{
        text-decoration: none;
    }
}


/**
 * Het team
 **/

.team-title-wrap{ 
    border-bottom: 1px solid $black;
    margin-bottom: 35px;
}

.team-member{
    img{
        margin-bottom: 25px;
    }

    h3,
    p{
        font-size: 20px;
        line-height: 30px;
        text-transform: inherit;
    }
    
    h3{
        font-weight: 700;
        margin-bottom: 0;
    }
}
/**
 * Header
**/
.header{
    .header-image{
        background-position: center;
        background-size: cover;
        height: 500px;
    }

    &--home{
        .header-image{
            @include media-breakpoint-down(md){
                background-position: center right 20%;
            }
        }
    }
}

.page-template-template-contact,
.page-template-template-blog,
.page-template-default{
    .header-image{
        height: 325px;
    }
}


.page-intro{
    margin-top: -255px;
    margin-bottom: 50px;
    opacity: 0;

    transform: translateX(-150px);

    animation-name: reveal-in;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    animation-delay: 1s; 

    &__inner{
        background-color: rgba($blue, .95);
        border-bottom: 15px solid $blue-secondary;
        padding: 45px 40px;
        padding-bottom: 0px;
    }

    h1{
        margin-bottom: 25px;
    }

    .btn{
        display: inline-block;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@keyframes reveal-in{
    from {opacity: 0;}
    to {opacity: 1; transform: translateX(0px);}  
}
/**
 * Footer
**/
.footer{
    border-top: 15px solid $blue-secondary;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 50px;

    ul{
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 40px;

        li{
            font-size: 16px;
        }

        li a{
            text-decoration: none;
        }
    }

    li,
    strong{
        line-height: 30px;
    }

    strong{
        font-size: 18px;
    }
}

.footer-baseline{
    span{
        font-size: 30px;
        font-weight: 300;
        text-transform: uppercase;
        line-height: 36px;
        margin-top: 50px;
        display: block;
    }
}

.footer-navigation,
.footer-contactinfo{
    padding-top: 35px;
}

.footer-final{
    font-size: 12px;

    .g-0{
        border-top: 1.5px solid $black;
        padding-top: 25px;
        padding-bottom: 45px;    
    }

    a{
        text-decoration: none;
    }
}

.professionele-schoonmaakdienst{
    h2{
        margin-top: 25px;
    }
}

.huishoudhulp{
    h2{
        margin-bottom: 55px;

        @include media-breakpoint-down(md){
            margin-top: 30px;
            margin-bottom: 25px;
        }
    }
}